import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';

const emotions = [
  {
    option: 'Alegría',
    color: '#FFFF00',
    description: 'Una sensación de gran placer y felicidad.',
  },
  {
    option: 'Tristeza',
    color: '#0000FF',
    description: 'Un sentimiento de pesar o melancolía.',
  },
  {
    option: 'Enfado o rabia',
    color: '#FF0000',
    description:
      'Una respuesta intensa a una situación de frustración o amenaza.',
  },
  {
    option: 'Miedo',
    color: '#000000',
    description:
      'Una emoción causada por la percepción de peligro, real o imaginario.',
  },
  {
    option: 'Calma',
    color: '#008000',
    description: 'Un estado de tranquilidad y paz interior.',
  },
  { option: 'Premio', color: 'GREY', description: '¡Has ganado un premio!' },

];

function duplicateOptions(options, times) {
  const duplicated = options.flatMap((option) => Array(times).fill(option));
  return shuffleArray(shuffleArray(shuffleArray(duplicated)));
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Intercambio de elementos
  }
  return array;
}

export default () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmotion, setSelectedEmotion] = useState(null);

  const [emo, setemo] = useState(duplicateOptions(emotions, 2));

  const data = emo.map((emotion) => ({
    option: emotion.option,
    style: { backgroundColor: emotion.color, textColor: '#ffffff' },
  }));

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const handleStopSpinning = () => {
    setMustSpin(false);
    setSelectedEmotion(emo[prizeNumber]);
    setModalOpen(true);
  };

  const Modal = ({ isOpen, onClose, emotion }) =>
    isOpen ? (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: '20px',
          zIndex: 100,
        }}
      >
        <h2>{emotion.option}</h2>
        <p>{emotion.description}</p>
        <button onClick={onClose}>Cerrar</button>
      </div>
    ) : null;

  return (
    <>
      <div
        style={{
          textAlign: '-webkit-center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          with: '100%',
          marginTop: '50px',
        }}
        onClick={handleSpinClick}
        className="container"
      >
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          onStopSpinning={handleStopSpinning}
          style={{ width: '100%', height: '100%' }} // Estilo para que la ruleta ocupe el contenedor
        />
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        emotion={selectedEmotion || {}}
      />
    </>
  );
};
