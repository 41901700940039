import React, { useState } from 'react';
import UserForm from './UserForm';
import EmotionWheel from './EmotionWheel';
import EmotionDisplay from './EmotionDisplay';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [selectedEmotion, setSelectedEmotion] = useState(null);

  return (
    
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Esto hace que el div ocupe toda la altura de la ventana
      }}
    >
      <EmotionWheel onEmotionSelect={setSelectedEmotion} />
    </div>
  );
}

export default App;
